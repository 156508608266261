import React, { useState } from "react";
import "./projects.css";

function Education() {
  const [selectedProject, setSelectedProject] = useState(null);

  // Dummy project data, replace with your own
  const projects = [
    {
      name: "Project 1",
      icon: "icon1.png",
      description: "Description 1",
      link: "link1",
    },
    {
      name: "Project 2",
      icon: "icon2.png",
      description: "Description 2",
      link: "link2",
    },
    // Add more projects as needed
  ];

  return (
    <div className="projects">
      {projects.map((project, index) => (
        <div
          className="project-box"
          key={index}
          onMouseEnter={() => setSelectedProject(project)}
          onMouseLeave={() => setSelectedProject(null)}
        >
          <img src={project.icon} alt={project.name} className="project-icon" />
          <p className="project-name">{project.name}</p>
          {selectedProject === project && (
            <div className="project-popup">
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noreferrer">
                Link
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Education;

import React from "react";
import "./About.css";

function About() {
  return (
    <section className="about-me">
      <h2>About Me</h2>
      <p>
        I enjoy building interesting things with interesting people. Over my
        Academic Career I have worked on a wide range of Software Development
        Projects, of varying scales, in varying roles.
      </p>
      <p>
        Outside of programming, I enjoy reading, playing Magic the Gathering,
        learning to play stooges songs and watching my favorite soccer team
        Arsenal F.C
      </p>
    </section>
  );
}

export default About;

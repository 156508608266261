import React from "react";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { CiFloppyDisk } from "react-icons/ci";
import Icon from "@mdi/react";
import { mdiSpaceInvaders } from "@mdi/js";
import About from "./About"; // Import the About component
import "./Header.css";

// If in src folder
import tigerIcon from "../rit.png";
import tritonIcon from "../ucsd.png";
function Header() {
  return (
    <div>
      <header className="header">
        <h1>
          <Icon path={mdiSpaceInvaders} size={3} />
          .ABHIK ROY's DIGITAL RESUME.
          <Icon path={mdiSpaceInvaders} size={3} />
        </h1>

        <div className="education-line">
          <p>
            HI! I am Abhik, an MS CS student at Rochester Institute of
            Technology
          </p>
          <img src={tigerIcon} alt="Tiger" className="tiger-icon" />
        </div>
        <div className="education-line">
          <p>
            I got my BS also in Computer Science from the University of
            California, San Diego
          </p>
          <img src={tritonIcon} alt="Tiger" className="triton-icon" />
        </div>
        <div className="third-line">
          <p>
            I am currently on the lookout for full-time new grad software
            engineering and data science roles starting 2024
          </p>
        </div>

        <div className="icon-container">
          <a
            href="https://github.com/abhik-roy"
            className="icon-button"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub size={40} />
            <p>github</p>
          </a>
          <a
            href="https://www.linkedin.com/in/abhik-roy-66a3a5100/"
            className="icon-button"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={40} />
            <p>Linkedin</p>
          </a>

          <a href="resume.pdf" download className="icon-button">
            <CiFloppyDisk size={40} />
            <p>Download My Resume</p>
          </a>

          <a href="mailto:email@example.com" className="icon-button">
            <FaEnvelope size={40} />
            <p>Email Me</p>
          </a>
        </div>
      </header>
      <About />
    </div>
  );
}

export default Header;

import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Header from "./components/Header";
import Education from "./components/Education";
import Skills from "./components/Skills";
import Navigation from "./components/Navigation";

function App() {
  return (
    <Router>
      <Navigation>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/experience">Education</Link>
      </Navigation>
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/about" element={<Education />} />
        <Route path="/experience" element={<Skills />} />
      </Routes>
    </Router>
  );
}

export default App;

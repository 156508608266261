import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaUser, FaProjectDiagram } from "react-icons/fa";
import "../App.css";
function Navigation() {
  return (
    <div className="navigation">
      <Link to="/" className="nav-button">
        <FaHome size={30} />
        <p>HOME</p>
      </Link>
      <Link to="/about" className="nav-button">
        <FaUser size={30} />
        <p>SWE PROJECTS</p>
      </Link>
      <Link to="/projects" className="nav-button">
        <FaProjectDiagram size={30} />
        <p>DATA SCIENCE PROJECTS</p>
      </Link>
    </div>
  );
}

export default Navigation;

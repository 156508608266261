import React from "react";

function Skills() {
  return (
    <section>
      <h2>Skills Summary</h2>
      <div>
        <p>
          Languages: Python, C++, Java, C, SQL, Unix scripting, MATLAB, ARM, x86
          assembly
        </p>
        <p>
          Tools: GIT, Docker, React Native, Node, Express, GraphQL, Pytorch,
          Tensorflow, OpenCV, cuda
        </p>
        <p>
          Courses Taken: ML Systems Implementation, Software Engineering,
          Computer Vision, Algorithms, Operating Systems
        </p>
      </div>
    </section>
  );
}

export default Skills;
